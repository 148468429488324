import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import About from "./Components/About";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import Chats from "./Components/Chats/ChatsSlide";
import ServicesSolutions from './Components/ServicesSolutions'
import Solutions from "./Components/Solutions";
import Audit from "./Components/Audit";
import Login from "./Components/Login";
import ContactCard from "./Components/ContactCard";
import Quota from "./Components/Quota";
import QuotaItem from "./Components/QuotaItem";
import Alert from './Components/Alert'
import { useState,useEffect } from "react";
import './App.css';
import AuthState from './Context/AuthState'
import DataState from './Context/DataState'
import ChatState from "./Context/Chats/ChatState";
import Register from "./Components/Register";
import Report from "./Components/Chats/Report";


function App() {
  const [alert, setalert] = useState(null)
  const [opacity, setOpacity] = useState(1);
  const showAlert = (message, type) => {
    setalert({
      msg: message,
      type: type
    })
    setTimeout(() => {
      setalert(null)
    }, 1200);
  }

  useEffect(() => {
    const startDate = new Date('2024-07-04').getTime();
    const endDate = new Date('2024-07-31').getTime();
    const totalDuration = endDate - startDate;

    const now = Date.now();
    let sessionStartDate = localStorage.getItem('sessionStartDate');

    if (!sessionStartDate) {
      localStorage.setItem('sessionStartDate', now);
      sessionStartDate = now;
    } else {
      sessionStartDate = parseInt(sessionStartDate, 10);
    }

    const elapsed = now - sessionStartDate;
    const remainingTime = Math.max(totalDuration - elapsed, 0);
    const newOpacity = Math.max(remainingTime / totalDuration, 0);

    setOpacity(newOpacity);

    if (remainingTime > 0) {
      const decrementInterval = totalDuration / 100; // 100 steps
      const interval = setInterval(() => {
        const currentTime = Date.now();
        const currentElapsed = currentTime - sessionStartDate;
        const currentRemainingTime = Math.max(totalDuration - currentElapsed, 0);
        const currentOpacity = Math.max(currentRemainingTime / totalDuration, 0);

        setOpacity(currentOpacity);

        if (currentOpacity <= 0) {
          clearInterval(interval);
        }
      }, decrementInterval);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <>
      <div className="Appi" style={{ opacity }}>
        <AuthState>
          <DataState>
            <ChatState>
              <BrowserRouter>
                <Navbar />
                <Alert alert={alert} />
                <Routes>
                  <Route path='/about' element={<About />} />
                  <Route path='/login' element={<Login showAlert={showAlert} />} />
                  <Route path='/quotaResponse' element={<QuotaItem showAlert={showAlert} />} />
                  <Route path='/quota' element={<Quota showAlert={showAlert} />} />
                  <Route path='/response' element={<ContactCard showAlert={showAlert} />} />
                  <Route path='/servicesandsolution' element={<ServicesSolutions />} />
                  <Route path='/contact' element={<Contact showAlert={showAlert} />} />
                  <Route path='/solutions' element={<Solutions />} />
                  <Route path='/audit' element={<Audit />} />
                  <Route path='/chats' element={<Chats />} />
                  <Route path='/report' element={<Report />} />
                  <Route path='/register' element={<Register showAlert={showAlert} />} />
                  <Route path='/' element={<Home />} />
                  {/* Add a catch-all route for unknown routes */}
                  <Route path='*' element={<Navigate to="/" />} />
                </Routes>
                <Footer />
              </BrowserRouter>
            </ChatState>
          </DataState>
        </AuthState>
      </div>
    </>
  );
}

export default App;
