import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ChatsContext from '../../Context/Chats/chatContext';
import Chats from './Chating';
import TicketModal from './TicketModal';
import Auth from '../../Context/authContext';

const ContactSlide = () => {
    const { TicketHeaders, ChatsFetch, DeleteTicket, TicketsForAdmin } = useContext(ChatsContext);
    const { access } = useContext(Auth);
    const [isAdminAccess, setIsAdminAccess] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [messages, setMessages] = useState([]);
    const [ticketingId, setTicketingId] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [ticketSubject, setTicketSubject] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchTickets = async () => {
        try {
            const response = isAdminAccess 
                ? await TicketsForAdmin(localStorage.getItem('userId')) 
                : await TicketHeaders();
            // console.log('Fetched tickets:', response.data);
            setTickets(response.data);
        } catch (error) {
            console.error('Error fetching tickets:', error);
        }
    };

    useEffect(() => {
        const checkAccess = async () => {
            try {
                const response = await access();
                // console.log('Access response:', response);
                setIsAdminAccess(response.adminAccess);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // console.log('Unauthorized');
                    setIsAdminAccess(false);
                } else {
                    console.error('Error fetching access:', error);
                }
            }
        };

        const initFetch = async () => {
            await checkAccess();
            setLoading(false);
        };

        initFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access]);

    useEffect(() => {
        if (!loading) {
            fetchTickets();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdminAccess, loading]);

    const handleSubjectClick = async (ticketId) => {
        try {
            const response = await ChatsFetch(ticketId);
            // console.log('Fetched chats:', response.data);
            setTicketingId(ticketId);
            setMessages(Array.isArray(response.data) ? response.data : [response.data]);
            const ticket = tickets.find(ticket => ticket.ticketId === ticketId);
            setTicketSubject(ticket ? ticket.subject : '');
            setStatus(ticket ? ticket.status : '');
        } catch (error) {
            console.error('Error fetching chat:', error);
        }
    };

    const handleDeleteTicketClick = async (ticketId) => {
        try {
            await DeleteTicket(ticketId);
            setTickets(tickets.filter(ticket => ticket.ticketId !== ticketId));
        } catch (error) {
            console.error('Error deleting ticket:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredTickets = tickets.filter(ticket =>
        ticket.subject.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div className="card scroll overflow-auto text-white" id="chat3" style={{ background: "rgba(0, 0, 0, 0.5)" }}>
                    <div className="card-body">
                        <div className="row">
                            <TicketModal />
                            <div className="border-end col-md-3 mb-4 mb-md-0" style={{ minHeight: "75vh", maxHeight: "95vh", overflowY: "auto" }}>
                                <div className="p-3">
                                    <div className="input-group rounded mb-3">
                                        <i className="fa-solid fa-arrow-left p-4" onClick={() => { navigate('/'); }}></i>
                                        <input
                                            type="search"
                                            className="form-control rounded"
                                            placeholder="Search"
                                            aria-label="Search"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                    <div className="m-2">
                                        <button className="btn btn-outline-light" data-bs-toggle="modal" data-bs-target="#merababu">
                                            <i className="fa-solid fa-plus"></i> Raise A Ticket
                                        </button>
                                        {isAdminAccess && (
                                            <button className="btn btn-outline-light m-2">
                                                <NavLink className="nav-link text-decoration-none" to="/report">Reports</NavLink>
                                            </button>
                                        )}
                                    </div>
                                    <div style={{ position: "relative", maxHeight: "100%" }}>
                                        <ul className="list-unstyled mb-0">
                                            {filteredTickets.map(ticket => (
                                                <li key={ticket._id} className="p-2 border-bottom">
                                                    <div className="d-flex justify-content-between" style={{ color: '#72b4f8' }}>
                                                        <div className="d-flex flex-row align-items-center">
                                                            <p className="fw-bold mb-0" style={{cursor: 'pointer'}} onClick={() => handleSubjectClick(ticket.ticketId)}>
                                                                Subject: {ticket.subject}
                                                            </p>
                                                        </div>
                                                        <p className="mb-0 text-white">Status: {ticket.status}</p>
                                                        <button className="btn btn-light bg-danger border-0 float-end fa-solid fa-trash" onClick={() => handleDeleteTicketClick(ticket.ticketId)}></button>
                                                    </div>
                                                    <p >Priority: {ticket.priority}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9" style={{ height: "95vh", overflowY: "auto" }}>
                                <Chats messages={messages} ticketingId={ticketingId} setMessages={setMessages} ticketSubject={ticketSubject} status={status} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ContactSlide;
