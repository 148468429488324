import React, { useState, useContext, useEffect } from 'react';
import ChatsContext from '../../Context/Chats/chatContext';
import Auth from '../../Context/authContext';

const Chats = ({ messages, ticketingId, setMessages, ticketSubject, status }) => {
    const { SendChat, updateTicket } = useContext(ChatsContext);
    const { access } = useContext(Auth);
    const [authorized, setAuthorized] = useState(true);
    const [chatsData, setChatsData] = useState({ ticketId: `${ticketingId}`, message: '' });
    const [stats, setStats] = useState('');
    const [isAdminAccess, setIsAdminAccess] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await access();
                setIsAdminAccess(response.adminAccess);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error('Unauthorized');
                    setAuthorized(false);
                } else {
                    console.error('Error fetching access:', error);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [access, ticketSubject, stats]);

    const handleChange = (e) => {
        setChatsData({ ...chatsData, message: e.target.value });
    };

    const handleClick = async () => {
        try {
            const chatData = { ...chatsData, ticketId: `${ticketingId}` };
            await SendChat(chatData);
            const whom = isAdminAccess ? 'admin' : 'normal';
            setMessages([...messages, { ...chatData, whom }]);
            setChatsData({ ...chatsData, message: '' });
        } catch (error) {
            console.error(error);
        }
    };

    const handleStats = async () => {
        try {
            const updatedStatus = stats || '';
            const updatedTicket = { ...ticketSubject, status: updatedStatus };
            await updateTicket(ticketingId, updatedTicket);
            setStats(updatedTicket.status);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <div className={`p-2 sticky-top ${ticketSubject === '' ? 'd-none' : 'd-block'}`} style={{ background: '#0c0d22' }}>
                <div className={`fw-bold fs-4 text-white `}>
                    Subject: {ticketSubject}
                    <div>
                        <p className={`mb-0 fw-bold text-${status === 'Open' ? 'success' : status === 'In Progress' ? 'warning' : 'danger'}`}>Status: {status}</p>
                        <select className={`mt-2 text-white ${isAdminAccess ? '' : 'd-none'}`} onClick={handleStats} style={{ background: "#0e0b1e" }} value={stats} onChange={(e) => setStats(e.target.value)}>
                            <option value="Open">Open</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Closed">Closed</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 main px-2 scroll m-2">
                        {!authorized ? <p>Unauthorized</p> : null}
                        {loading ? <p className={`d-${authorized ? 'block' : 'none'}`}>Loading...</p> : (messages.length === 0 && !ticketSubject) ? <p className={`d-${authorized ? 'block' : 'none'}`}>Start the conversation</p> : null}
                        {loading ? <p className={`d-${authorized ? 'block' : 'none'}`}>Loading...</p> : (messages.length === 0) ? <p className={`d-${authorized ? 'block' : 'none'}`}>No chats</p> : null}
                        {messages.map((msg, index) => (
                            <div key={index} className="">
                                {isAdminAccess ? (msg.whom === 'normal' ? (
                                    <div className='d-flex align-items-center py-3'>
                                        <div className="pr-2 pl-1 " key={msg._id}>
                                            <span className="name justify-content-start">Client</span>
                                            <p className="text-bg-secondary rounded px-3 text-center">{msg.message}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='d-flex align-items-center justify-content-end py-3' key={msg._id}>
                                        <div className="pr-2">
                                            <span className="name">Admin</span>
                                            <p className=" text-bg-success rounded px-3 text-center">{msg.message}</p>
                                        </div>
                                    </div>
                                )) : (msg.whom === 'normal' ? (
                                    <div className='d-flex align-items-center justify-content-end py-3'>
                                        <div className="pr-2 pl-1 " key={msg._id}>
                                            <span className="name justify-content-end ">You</span>
                                            <p className="text-bg-success rounded px-3 text-center">{msg.message}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='d-flex align-items-center py-3' key={msg._id}>
                                        <div className="pr-2">
                                            <span className="name">Admin</span>
                                            <p className="text-bg-secondary rounded px-3 text-center">{msg.message}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className={`sticky-bottom ${ticketSubject === '' ? 'd-none' : 'd-block'} ${authorized ? 'd-block' : 'd-none'}`} style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
                        <div className="input-group p-2 sticky-bottom z-3">
                            <input type="text" className="form-control text-white chatForm" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", outline: 'none' }} value={chatsData.message} onChange={handleChange} onKeyDown={(e) => { if (e.key === 'Enter') handleClick(); }} />
                            <button type="button" onClick={handleClick} className="btn btn-dark">Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chats;
