import React, { useEffect } from "react";
import AboutUs from "../Assets/AboutUs.json";
import Lottie from "lottie-react";
import amey from '../Assets/amey.jpeg'
import AOS from 'aos'
import "aos/dist/aos.css"
const About = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 })


  }, [])
  
  return (
    <div>
      {/* <!-- About 1 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8 container">
        <div className="container">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center" data-aos="fade-up">
            <div className="col-12 col-lg-6 col-xl-5">
              <Lottie animationData={AboutUs} loop={true} autoplay={true} />
            </div>
            <div className="col-12 col-lg-6 col-xl-7">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <h2 className="h1 mb-3">Who Are We?</h2>
                  <p className="lead fs-5 text-secondary mb-3" style={{lineHeight: "1.8"}}>
                    Saga Technologies is a leading technology solutions company,
                    which has rapidly multiplied the quantum of its business
                    over the past many years. Despite being in a very saturated
                    line of business, where there are companies galore jostling
                    for space and market share, Saga has speed ahead due to the
                    unique nature of its offerings and established itself as an
                    IT company with a difference.
                  </p>
                  <p className="mb-5"style={{lineHeight: "1.8"}}>
                    Our thought process in any business opportunity is that of a
                    consultant, rather than that of a salesperson. Our intent at
                    Saga, at all times, is to be thorough in our approach,
                    whatever be the nature and size of the opportunity at hand.
                    With deep knowledge in-house and access to thoroughbred
                    thought leaders in the field as collaborators, as well as
                    the best-in-class OEMs, you can be rest assured that every
                    single proposal you see from us, is well thought out.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center" data-aos="fade-up">
            <div className="col-12 col-lg-6 col-xl-7">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11" style={{lineHeight: "1.8"}}>
                  <h2 className="h1 mb-3">Our Leadership </h2>
                  <p className="lead fs-5 text-secondary mb-3">
                    Dr. Amey Naik is a PH.D Holder in Cyber Security and MBA in Information Technology, armed with CISA, CEH and
                    multiple other certifications in Networking and Cyber
                    Security, Amey started early, and has garnered over a decade
                    of work experience, while still short of his 30th birthday -
                    a young achiever already!  He has been exposed to pretty much every aspect of the IT
                    business – be it engineering, solutions consulting, service
                    delivery, or just pure sales.
                  </p>
                  {/* <p className="mb-5" style={{lineHeight: "1.8"}}>
                    Everyone who interacts with
                    him will surely agree that his business acumen is far
                    superior to most others his age.
                    <br />
                    Off work, Amey is a party animal, apart from being an avid gamer and a movie / OTT content buff. Bitten by the travel bug, he has already traversed a fairly large part of the country. Lastly, his people skills are exemplary, making him a natural leader.
                  </p> */}
                </div>
              </div>
            </div>
              <div className="col-12 col-lg-6 col-xl-5 text-center">
             <img src={amey} alt="" />
             <div className=" fs-2 fw-bold">
              Dr. Amey Naik
             </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
