import React from "react";
import logo from '../Assets/logo.png'
import { Link } from "react-router-dom";

const Footer = () => {

  const today = new Date();
  const visibleAfterDate = new Date('2024-08-12');
// eslint-disable-next-line
  const shouldDisplayDeveloperInfo = today >= visibleAfterDate;

  return (
    <div>
      {/* Footer */}
      <footer className="text-center text-lg-start text-white" style={{ background: "#171b1e" }}>
        <div className="container p-4 pb-0">
          {/* Section: Links */}
          <section className="">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <div className="text-uppercase mb-4 font-weight-bold">
                  <img style={{ maxHeight: '20vh' }} src={logo} alt="Logo" />
                </div>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Services
                </h6>
                <p>
                  <Link to="/audit" className="text-white text-decoration-none">Audits</Link>
                </p>
                <p>
                  <Link to="/servicesandsolution" className="text-white text-decoration-none">Services</Link>
                </p>
                <p>
                  <Link to="/solutions" className="text-white text-decoration-none">Solution</Link>
                </p>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Useful links
                </h6>
                <p>
                  <Link to="/about" className="text-white text-decoration-none">About</Link>
                </p>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Contact
                </h6>
                <p>
                  <i className="fas fa-home mr-3"></i> Dubia, UAE
                </p>
                <p>
                  <i className="fas fa-envelope mr-3"></i> sagatechnologies.ae@gmail.com
                </p>
                <p>
                  <i className="fas fa-phone mr-3"></i> +971 5851 29327
                </p>
                <p>
                  <i className="fas fa-phone mr-3"></i> +91 93218 02660
                </p>
              </div>
            </div>
          </section>
          {/* Section: Links */}
          <hr className="my-3" />
          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                <div className="p-3">
                  &copy; 2024 Copyright: 
                  <Link to="/" className="text-white text-decoration-none">
                     {' '}Saga Technologies LLC
                  </Link>
                </div>
              </div>
              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                <Link to="https://www.facebook.com/sagatechindia?lang=en" className="btn btn-outline-light btn-floating m-1 text-white" role="button">
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link to="https://www.linkedin.com/company/sagatechnologiesindia/" className="btn btn-outline-light btn-floating m-1 text-white" role="button">
                  <i className="fab fa-linkedin"></i>
                </Link>
                <Link to="https://www.youtube.com/channel/UCGb0thurrgCfbJWduxb6IqQ" className="btn btn-outline-light btn-floating m-1 text-white" role="button">
                  <i className="fab fa-youtube"></i>
                </Link>
              </div>
            </div>
          </section>
          {/* Section: Copyright */}
        </div>
      </footer>
      {/* Footer */}

      {/* Developer info block */}
      {/* {shouldDisplayDeveloperInfo && (
        <div className="text-center p-1" style={{ background: "#0f1214", font: "0.8rem sans-serif", color: "white" }}>
          Developed By <a href="mailto:rehanansari0912@gmail.com" style={{ color: "white" }}>Rehan</a> 2024 rehanansari0912@gmail.com
        </div>
      )} */}
    </div>
  );
};

export default Footer;
