import AuthContext from './authContext'
import axios from 'axios';


const AuthState = (props) => {
    const riobhai = {
        "name": "Kise ho ",
        "Mode": "Halal"
    }
    const authLogin = async (credentials) => {
        try {
            const response = await axios.post('https://saga-backend.vercel.app/api/login', credentials);
            // console.log(response.data);
            localStorage.setItem('data', response.data.token)
            return response; // Return the response
        } catch (error) {
            console.error('Error during login:', error);
            throw error; // Throw the error to handle it in the component
        }
    }

    const access = async () => {
        try {
            const headers = {
                'Authorization': `${localStorage.getItem('data')}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.get('https://saga-backend.vercel.app/api/access', { headers });
            // console.log(response.data);
            return response.data; // Return the response data
        } catch (error) {
            console.error('Error during login:', error);
            throw error;
        }
    };

    const register = async (credentials) => {
        try {
            const response = await axios.post('https://saga-backend.vercel.app/api/register', credentials);
            return response; // Return the response
        } catch (error) {
            console.error('Error during signup:', error);
            throw error; // Throw the error to handle it in the component
        }
    }


    return (
        <AuthContext.Provider value={{ riobhai, authLogin, access, register }}>
            {props.children}
        </AuthContext.Provider>
    )
}
export default AuthState