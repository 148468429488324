import React,{useEffect} from "react";
import server from "../Assets/server.svg";
import managedServices from "../Assets/managedSerivces.svg";
import service_illustration from "../Assets/service_illustration.svg";
import computing from "../Assets/computing.svg";
import vault from "../Assets/vault.svg";
import consult from "../Assets/consulting.svg";
import security from "../Assets/security.svg";
import nocsoc from "../Assets/NOC_SOC.svg";
import compliance from "../Assets/compliance.svg";
import sharedResourse from "../Assets/shared-resourse.svg";
import remote from "../Assets/remote.svg";
import AOS from 'aos'
import "aos/dist/aos.css"

const ServicesSolutions = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 })


  }, [])
  return (
    <div>
      {/* <!-- Service 1 - Bootstrap Brain Component --> */}
      <section className="py-5 py-xl-8">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center">Services</h2>
              <p className="text-secondary mb-5 text-center">
              The service delivery team provides you with a range of services to help you achieve your business goals. Having the right service delivery team is crucial for your business success.
              </p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container overflow-hidden">
          <div className="row gy-5">
            <div className="col-12 col-sm-6 col-lg-4" data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={computing}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />

                <h5 className="m-2">Managed IT Services </h5>
                <p className="m-0 text-secondary">
                  Leave your day to day IT support responsibilities in the hands
                  of our service delivery team, and onsite support team, backed
                  by a bunch of backup engineers
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4" data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={managedServices}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">Facility Managed Services</h5>
                <p className="m-0 text-secondary">
                  If you are a Medium or Large Enterprise, we offer ITIL
                  framework-based service delivery model, specially designed for
                  you, to deliver the optimum level of support.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4" data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={service_illustration}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />

                <h5 className="m-2">AMC Services</h5>
                <p className="m-0 text-secondary">
                  Your malfunctioning IT equipment will never stay unattended
                  for too long when you avail of our prompt and cost-effective
                  AMC services.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4" data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={server} alt="" srcSet="" style={{ height: "18vh" }} />

                <h5 className="m-2">IT Resource Deployment</h5>
                <p className="m-0 text-secondary">
                  Do not worry about the task of recruiting and managing IT
                  resources. We will identify the right resource for the right
                  job profile.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4" data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={vault} alt="" srcSet="" style={{ height: "18vh" }} />

                <h5 className="m-2">IT Infrastructure Audits</h5>
                <p className="m-0 text-secondary">
                  Get your IT Infrastructure analysed by one of the most
                  experienced CTOs.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 "  data-aos="fade-up">
              <div className="text-center px-xl-2">
              <img
                  src={consult}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">IT Management Consulting</h5>
                <p className="m-0 text-secondary">
                  Leave all your IT Management and Strategy thinking to us, and
                  focus on your core business.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 "  data-aos="fade-up">
              <div className="text-center px-xl-2">
              <img
                  src={security}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">Information Security Audits</h5>
                <p className="m-0 text-secondary">
                  Experts look into all possible security loopholes and lapses
                  in your IT environment.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 "  data-aos="fade-up">
              <div className="text-center px-xl-2">
              <img
                  src={nocsoc}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">NOC / SOC Services</h5>
                <p className="m-0 text-secondary">
                  Leave the complicated task of managing your NOC and / or SOC
                  to our highly trained experts.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 "  data-aos="fade-up">
              <div className="text-center px-xl-2">
              <img
                  src={compliance}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">Compliance Consulting</h5>
                <p className="m-0 text-secondary">
                  Software Licensing simplified. We recommend optimum licensing
                  compliance strategies.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 "  data-aos="fade-up">
              <div className="text-center px-xl-2">
              <img
                  src={sharedResourse}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">Shared IT Manager Services</h5>
                <p className="m-0 text-secondary">
                  Get access to a full fledged IT support team, managed by a
                  senior technical resource.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 "  data-aos="fade-up">
              <div className="text-center px-xl-2">
              <img
                  src={remote}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">Remote IT Support Services</h5>
                <p className="m-0 text-secondary">
                  It doesn't really matter where your IT Infrastructure is
                  located. We will provide the support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
};

export default ServicesSolutions;
