import ChatContext from './chatContext'
import axios from 'axios';


const ChatState = (props) => {
    const riobhai = {
        "name": "Kise ho ",
        "Mode": "Bait-ul-khala"
    }
    const TicketHeaders = async () => {
        try {
            const headers = {
                'Authorization': `${localStorage.getItem('data')}`, // Include the JWT token in the Authorization header
                'Content-Type': 'application/json' // Specify the content type as JSON
            };
            const response = await axios.get('https://saga-backend.vercel.app/api/tickets', { headers });

            // console.log(response.data);
            // localStorage.setItem('data', response.data.token)
            return response; // Return the response
        } catch (error) {
            console.error(error);
            throw error; // Throw the error to handle it in the component
        }
    }

    // Tickets for admin 

    const TicketsForAdmin = async (userId) => {
        try {
            const headers = {
                'Authorization': `${localStorage.getItem('data')}`, // Include the JWT token in the Authorization header
                'Content-Type': 'application/json' // Specify the content type as JSON
            };
            const response = await axios.get(`https://saga-backend.vercel.app/api/tickets/userId/${userId}`, { headers });
            return response;
        } catch (error) {
            console.error(error);
            throw error; // Throw the error to handle it in the component
        }
    }
const ChatsFetch = async (ticketNumber) => {
        try {
            const headers = {
                'Authorization': `${localStorage.getItem('data')}`, // Include the JWT token in the Authorization header
                'Content-Type': 'application/json' // Specify the content type as JSON
            };
            const response = await axios.get(`https://saga-backend.vercel.app/api/chats/${ticketNumber}`, { headers });
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    const SendChat = async (chatData) => {
        try {
            const headers = {
                'Authorization': `${localStorage.getItem('data')}`, // Include the JWT token in the Authorization header
                'Content-Type': 'application/json' // Specify the content type as JSON
            };

            const response = await axios.post('https://saga-backend.vercel.app/api/chats', chatData, { headers });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error; // Throw the error to handle it in the component
        }
    }

    // create a function to add ticket 
    const AddTicket = async (ticketData) => {
        try {
            const headers = {
                'Authorization': `${localStorage.getItem('data')}`, // Include the JWT token in the Authorization header
                'Content-Type': 'application/json' // Specify the content type as JSON
            };
            // console.log(ticketData);
            const response = await axios.post('https://saga-backend.vercel.app/api/tickets', ticketData, { headers });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error; // Throw the error to handle it in the component
        }
    }

    //create an fucntion to delete ticket
    const DeleteTicket = async (ticketId) => {
        try {
            const headers = {
                'Authorization': `${localStorage.getItem('data')}`, // Include the JWT token in the Authorization header
            };
            const response = await axios.delete(`https://saga-backend.vercel.app/api/tickets/${ticketId}`, { headers });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error; // Throw the error to handle it in the component
        }
    }

    // Accpeting names 

    const AcceptNames = async () => {
        try {
            const headers = {
                'Authorization': `${localStorage.getItem('data')}`, // Include the JWT token in the Authorization header
                'Content-Type': 'application/json' // Specify the content type as JSON
            };
            const response = await axios.get('https://saga-backend.vercel.app/api/names', { headers });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error; // Throw the error to handle it in the component
        }
    }


    const updateTicket = async (ticketId, updatedTicket) => {
        try {
            const headers = {
                'Authorization': `${localStorage.getItem('data')}`, // Include the JWT token in the Authorization header
                'Content-Type': 'application/json' // Specify the content type as JSON
            };
            const response = await axios.put(`https://saga-backend.vercel.app/api/tickets/${ticketId}`, updatedTicket, { headers });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error; // Throw the error to handle it in the component
        }
    };

    return (
        <ChatContext.Provider value={{ riobhai, TicketHeaders, ChatsFetch, SendChat, AddTicket, DeleteTicket, AcceptNames, TicketsForAdmin, updateTicket }}>
            {props.children}
        </ChatContext.Provider>
    )
}
export default ChatState