import React, { useContext, useState, useRef } from 'react';
import chatContext from '../../Context/Chats/chatContext';

const TicketModal = () => {
    const { AddTicket } = useContext(chatContext);
    const [Subject, setSubject] = useState('');
    const [Priority, setPriority] = useState('');
    const closeBtnRef = useRef(null); // Create a ref for the close button



    const handleClick = async (e) => {
        e.preventDefault();
        const ticketData = {
            "subject": Subject,
            "priority": Priority
        };
        await AddTicket(ticketData);
        // Enable the close button after the form is submitted
        closeBtnRef.current.click(); // Simulate a click on the close button
    };

    return (
        <div>
            <div className="modal fade" id="merababu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content bg-dark text-light">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" className="btn-close" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor=" " className="form-label">Problem or Subject</label>
                                    <input type="text" placeholder="Enter problem or subject" className="form-control bg-dark text-light" id="exampleFormControlInput1" onChange={(e) => setSubject(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlSelect1" className="form-label">Priority<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-select bg-dark text-light" id="exampleFormControlSelect1" required onChange={(e) => setPriority(e.target.value)}>
                                        <option value="">Select one</option>
                                        <option>High</option>
                                        <option>Medium</option>
                                        <option>Low</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeBtnRef}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleClick}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TicketModal;
