import React from "react";
import mcafee from "../Assets/pather-mcafee.png";
import cisco from "../Assets/partner-cisco.png";
import google from "../Assets/google.svg";
import microsoft from "../Assets/partner-microsoft.png";

const Partners = () => {
  return (
    <div>
      {/* <!-- Client 2 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h3 className="fs-6 text-secondary mb-2 text-uppercase text-center">
                Our partners
              </h3>
              <h2 className="mb-4 display-5 text-center">
                Allienced with over 100+ partners.
              </h2>
             
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container overflow-hidden">
          <div className="row gy-4 justify-content-center align-items-center">
            <div className="col-6 col-md-4 col-lg-3 text-center">
              <div className="text-secondary px-3 py-3">
                <img
                  src={google}
                  alt="Google"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 text-center">
              <div className="text-secondary px-3 py-3">
                <img
                  src={microsoft}
                  alt="Microsoft"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 text-center">
              <div className="text-secondary px-3 py-3">
                <img
                  src={mcafee}
                  alt="McAfee"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "25vh" }}
                />
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 text-center">
              <div className="text-secondary px-3 py-3">
                <img
                  src={cisco}
                  alt="Cisco"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partners;
