import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DataContext from '../Context/dataContext';
import Auth from '../Context/authContext';

const QuotaItem = ({ showAlert }) => {
    const { companies, fetchCompanies, companyDelete } = useContext(DataContext);
    const navigate = useNavigate();
    const { access } = useContext(Auth);
    // eslint-disable-next-line
    const [isAdminAccess, setIsAdminAccess] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await access();
                setIsAdminAccess(response.adminAccess);
                if (!response.adminAccess) {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching access:', error);
            }
        };
        fetchData();
    }, [access, navigate]);

    useEffect(() => {
        fetchCompanies();
        if (!localStorage.getItem('data')) {
            navigate('/');
        }
    }, [fetchCompanies, navigate]);

    return (
        <div>
            <div className="container">
                <Link to={'/'} className="btn btn-light disabled">Quota Response</Link>
                <Link to={'/response'} className="btn btn-outline-light mx-2">Contact Response</Link>
                <Link to={'/chats'} className="btn btn-outline-light mx-2">Chats</Link>
                <button onClick={() => { localStorage.removeItem('data'); navigate('/'); showAlert('Logout Successfully', 'success'); }} className="btn btn-outline-light">Log Out</button>

                <div className='row my-3'>
                    {companies.map(company => (
                        <div key={company._id} className='col-md-6 my-2'>
                            <div className='card bg-dark text-light'>
                                <div className='card-body'>
                                    <h5 className='card-title'>{company.company_name}</h5>
                                    <div className='card-text my-1'><>Company Address:</> {company.company_address}</div>
                                    <div className='card-text my-1'><>Contact Person (Technical):</> {company.contact_person_technical}</div>
                                    <div className='card-text my-1'><>Contact Person Email:</> {company.contact_person_email}</div>
                                    <div className='card-text my-1'><>Number of Locations:</> {company.number_of_locations}</div>
                                    <div className='card-text my-1'><>Contact Phone Number:</> {company.contact_phone_number}</div>
                                    <div className='card-text my-1'><>Number of IT Assets:</> {company.number_of_it_assets}</div>
                                    <div className='card-text my-1'><>Clearing Number:</> {company.clearing_number}</div>
                                    <div className='card-text my-1'><>Exchanges:</> {company.exchanges}</div>
                                    <div className='card-text my-1'><>Firewall:</> {company.firewall}</div>
                                    <div className='card-text my-1'><>Active Directory:</> {company.active_directory}</div>
                                    <div className='card-text my-1'><>In-House Built Application:</> {company.in_house_built_application}</div>
                                    <div className='card-text my-1'><>Algorithmic Trading:</> {company.algo_trading}</div>
                                    <div className='card-text my-1'><>GST Number:</> {company.gst_number}</div>
                                    <div className='card-text my-1'><>How Did You Hear About Us:</> {company.how_did_you_hear_about_us}</div>
                                    <div className='card-text my-1'><>Comments:</> {company.comments}</div>
                                    <button className='btn mx-2 btn-outline-light' onClick={() => companyDelete(company._id)} >
                                        <i className="fa-solid fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuotaItem;
