import React, {  useEffect, useState } from 'react';
import {  useLocation } from "react-router-dom";



const Alert = (props) => {
  const [available, setAvailable] = useState(true)

    const location = useLocation();
    useEffect(() => {
     
      if (location.pathname === "/chats") {
        setAvailable(false)
      }
     
    },[location.pathname])

    return (
        <div className={`modal-dialog modal-dialog-scrollable ${available === false ? 'd-none ' : "d-block"}`}>
            <div style={{ height: '34px' }} >
                {props.alert && <div className={`alert alert-${props.alert.type} alert-dismissible fade show `} role="alert">
                    <strong> </strong>{props.alert.msg}

                </div>}
            </div>
        </div>
    );
};

export default Alert;