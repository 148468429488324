import React, { useState,useEffect } from "react";
import axios from 'axios';
import Lottie from "lottie-react";
import contact from '../Assets/contact.json';
import AOS from 'aos'
import "aos/dist/aos.css"

const Contact = ({ showAlert }) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })


  }, [])
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_no: "",
    subject: "",
    description: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData);
      const response = await axios.post('https://saga-backend.vercel.app/api/contacts', formData);
      console.log(response.data); // You can handle the response data as needed

      // Reset the form after successful submission
      setFormData({
        full_name: "",
        email: "",
        phone_no: "",
        subject: "",
        description: ""
      });
      showAlert('Form Submited Successful', 'success')

    } catch (error) {
      console.error('Error submitting form:', error);
      showAlert('Error in submitting retry or try after sometime ', 'danger')

    }
  };

  return (
    <div>
      <section className="py-3 py-md-5 text-white" data-aos="fade-up">
        <div className="container">

          <div className="row justify-content-md-center">

            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h3 className="fs-6  mb-2 text-uppercase text-center" style={{ color: "#00ffe8" }}>
                Get in Touch
              </h3>
              <h2 className="display-5 mb-4 mb-md-5 text-center" >
                We're always on the lookout to work with new clients.
              </h2>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-xl-center">
            <div className="col-12 col-lg-6">
              <Lottie animationData={contact} loop={true} autoplay={true} />
            </div>
            <div className="col-12 col-lg-6">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <div className="border rounded shadow-sm overflow-hidden">
                    <form onSubmit={handleSubmit}>
                      <div className="row gy-4 gy-xl-5 p-4 p-xl-5">
                        <div className="col-12">
                          <label htmlFor="full_name" className="form-label">
                            Full Name <span className="text-danger">*</span>
                          </label>
                          <input
                            style={{ background: "#0e0b1e" }}
                            type="text"
                            className="form-control text-white"
                            id="full_name"
                            name="full_name"
                            value={formData.full_name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label htmlFor="email" className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <div className="input-group ">
                            <input
                              style={{ background: "#0e0b1e" }}
                              type="email"
                              className="form-control text-white"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <label htmlFor="phone_no" className="form-label">
                            Phone Number
                          </label>
                          <div className="input-group">
                            <input
                              style={{ background: "#0e0b1e" }}
                              type="tel"
                              className="form-control text-white"
                              id="phone_no"
                              name="phone_no"
                              value={formData.phone_no}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <label htmlFor="subject" className="form-label">
                            Subject <span className="text-danger">*</span>
                          </label>
                          <input
                            style={{ background: "#0e0b1e" }}
                            type="text"
                            className="form-control text-white"
                            id="subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="description" className="form-label">
                            Message <span className="text-danger">*</span>
                          </label>
                          <textarea
                            style={{ background: "#0e0b1e" }}
                            className="form-control text-white"
                            id="description"
                            name="description"
                            rows="3"
                            value={formData.description}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                        <div className="col-12">
                          <div className="d-grid">
                            <button
                              className="btn btn-primary btn-lg"
                              type="submit"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
