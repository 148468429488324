import React,{useEffect} from "react";
import protect from "../Assets/protect.svg";
import audits from "../Assets/audits.svg";
import secureServer from "../Assets/secureServer.svg";
import SIEM from "../Assets/SIEM.svg";
import Detection from "../Assets/Detection.svg";
import portfolio from '../Assets/portfolio.svg'
import license from "../Assets/license.svg";
import vault from "../Assets/vault.svg";
import NOC_SOC from "../Assets/NOC_SOC.svg";
import banking from "../Assets/banking.svg";
import application from "../Assets/application.svg";
import security from "../Assets/security.svg";
import updates from "../Assets/updates.svg";
import AOS from 'aos'
import "aos/dist/aos.css"



const Audit = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 })


  }, [])
  return (
    <div>
      {/* <!-- Audit Services - Bootstrap Brain Component --> */}
      <section className="py-5 py-xl-8">
        <div className="container"  >
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center">Audits</h2>
              <p className="text-secondary mb-5 text-center">
                We Help You Comply to all Audit Guidelines. Saga Technologies
                offers a comprehensive range of Audit services for organizations
                of all sizes and lines of business, with specific strength in
                the BFSI segment.
              </p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container overflow-hidden" >
          <div className="row gy-5 gx-md-4 gy-lg-0 gx-xxl-5 justify-content-center">
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={protect} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">Cyber Security Audits</h4>
              <p className="mb-3 text-secondary">
                Saga Technologies conducts Cyber Security and Cyber Resilience
                (CSCR) audits, IT Infrastructure and Systems Audits for Stock
                Broking, and Portfolio Management firms as per SEBI guidelines.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={audits} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">Internal & Statutory Audits</h4>
              <p className="mb-3 text-secondary">
                We conduct Internal and Statutory Audits for our clients in
                compliance with industry standards and regulations.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={secureServer} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">VAPT</h4>
              <p className="mb-3 text-secondary">
                We offer comprehensive VAPT services to identify vulnerabilities
                and strengthen the security posture of your organization.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={SIEM} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">SIEM Services</h4>
              <p className="mb-3 text-secondary">
                We provide Security Information and Event Management services
                for effective monitoring and detection of security incidents,
                ensuring proactive threat management.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={Detection} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">Monitoring & Detection Services</h4>
              <p className="mb-3 text-secondary">
                Our monitoring and detection services ensure real-time
                visibility into your IT infrastructure, enabling swift response
                to security threats.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={license} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">Web Application Firewall</h4>
              <p className="mb-3 text-secondary">
                Saga Technologies conducts Web Application Firewall (WAF) audits for businesses, ensuring their web applications are protected against cyber threats.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={portfolio} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">Managing Portflio Cyber Security</h4>
              <p className="mb-3 text-secondary">
                Our tailored portfolio cyber security management service provides robust protection for stock brokers, ensuring comprehensive defense against cyber threats and adherence to industry regulations.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={vault} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">Infomation Security Audit </h4>
              <p className="mb-3 text-secondary">
                Information Security Audit: Comprehensive assessment of digital infrastructure to identify vulnerabilities and ensure compliance
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={NOC_SOC} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">System Audit </h4>
              <p className="mb-3 text-secondary">
                Thorough examination of IT infrastructure and systems to assess performance, identify potential risks, and optimize operations for enhanced efficiency and security.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={banking} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">INSP Audit </h4>
              <p className="mb-3 text-secondary">
                Rigorous examination of banking services to ensure compliance and effectiveness, identifying immediate needs and providing strategic recommendations for enhanced support and service delivery.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={application} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">ASP Audit</h4>
              <p className="mb-3 text-secondary">
                A comprehensive review of Application Service Provider (ASP) systems and services, aimed at evaluating performance, security, and compliance to optimize operational efficiency and mitigate risks.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={security} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">Data Loss Preventation</h4>
              <p className="mb-3 text-secondary">
                Implementing measures to safeguard sensitive data from unauthorized access, leakage, or theft.
              </p>
            </div>
            <div className="col-11 col-sm-6 col-lg-3" data-aos="fade-up">
              <img src={updates} alt="" srcSet="" style={{ height: "18vh" }} className="mb-2" />

              <h4 className="mb-3">Patch Managment </h4>
              <p className="mb-3 text-secondary">

                Ensuring timely deployment of software updates and patches to mitigate vulnerabilities and enhance system security.
              </p>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default Audit;
