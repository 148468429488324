import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatContext from '../../Context/Chats/chatContext';

const Report = () => {
    const { AcceptNames, TicketHeaders } = useContext(ChatContext);
    const [names, setNames] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const names = await AcceptNames();
                setNames(names);
            } catch (error) {
                console.error('Error fetching names:', error);
            } finally {
                setLoading(false); // Set loading to false when fetching is completed
            }
        };
        fetchData();
    }, [AcceptNames]);

    const navigate = useNavigate();
    const handleClick = (userId) => {
        console.log(userId);
        TicketHeaders(userId)
        localStorage.setItem('userId', userId)
        navigate('/chats')
    }

    return (
        <div className="container">
            <div className="row">
                {/* Render loading indicator if data is still loading */}
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    // Render the list of names when data is fetched
                    names.map(name => (
                        <div key={name.userId} className="col-md-4">
                            <div className="m-2 card bg-dark text-light">
                                <div className="card-body d-flex justify-content-between align-items-center">
                                    <span>{name.userName}</span>
                                    {/* Corrected onClick */}
                                    <button className="btn btn-outline-light fa-solid fa-arrow-right" onClick={() => handleClick(name.userId)}></button>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Report;
