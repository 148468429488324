import React, { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import Lottie from "lottie-react";
import data_center from "../Assets/data_center.json";
import hearts from "../Assets/Heart.svg";
import working from "../Assets/working.json";
import analysis from "../Assets/analysis.json";
import digital from "../Assets/digital edited-1.png";
import social from "../Assets/social.svg";
import secure from "../Assets/secure.svg";
import support from "../Assets/support.svg";
import Partners from "./Partners";
import AOS from 'aos'
import "aos/dist/aos.css"
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'

const Home = () => {
  const [imageHeight, setImageHeight] = useState("65vh");
  const [minWidth, setMinWidth] = useState("100%");
  const [counter, setCounter] = useState(false)

  useEffect(() => {
    AOS.init({ duration: 2000 })


  }, [])

  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth < 768) {
        setImageHeight("65vh");
        setMinWidth("10vh");
      } else {
        setImageHeight("35vh");
        setMinWidth("100%");
      }
    };

    handleResize(); // Call handleResize initially to set the correct height and minWidth
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []); // Empty dependency array ensures useEffect runs only once

  return (
    <div>

      <section className=" container overflow-hidden">
        <div data-aos=""
          className={` container row ${window.innerWidth < 768
            ? "align-items-center justify-content-center vh-100"
            : "align-items-center"
            } `}
        >
          <div className="col-md-4 p-0">
            {/* <div
              className="fw-bolder"
              style={{ color: "#00ffe8", fontSize: "1.1rem" }}
            >
              The Best Cyber Security For You
            </div> */}
            <div
              className=" fw-bolder "
              style={{ color: "#2775ad", fontSize: "2.9rem" }}
            >
              Saga Technologies
            </div>
            <div className="fw-" style={{ fontSize: "1rem", color: "#c5c5c5" }}>
              Saga Technologies offers a comprehensive range of audit,
              compliance and Cyber Security for all sizes of business
            </div>
            <div className="" style={{ fontSize: "1.4rem", color: "#FFF" }}>
              Best At
              <TypeAnimation
                sequence={[
                  " Innovate Solutions  ",
                  2000,
                  "  Cyber Security",
                  2000,
                  " Building Trust",
                  2000,
                  " Compliance ",
                  2000,
                ]}
                wrapper="span"
                speed={50}
                style={{ display: "inline-block", paddingLeft: "5px" }}
                repeat={Infinity}
              />
            </div>
          </div>
          <div className="col-md-8">
            <Lottie animationData={data_center} loop={true} autoplay={true} />
          </div>
        </div>

        <div className="container" data-aos="fade-up">
          <Partners />
        </div>

        <div className="container mt-5" data-aos="fade-down"
          data-aos-easing="linear">
          <div className="container">
            {/* <!-- Service 4 - Bootstrap Brain Component --> */}
            <section className=" py-5 py-xl-8" data-aos="zoom-out-down">
              <div className="container">
                <div className="row justify-content-md-center">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-7">
                    <h3
                      className="fs-6 mb-2 text-center text-uppercase"
                      style={{ color: "#00ffe8" }}
                    >
                      What We Do?
                    </h3>
                    <h2 className="display-5 mb-5 text-center">
                      We are giving you perfect solutions with our proficient
                      services.
                    </h2>
                    <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                  </div>
                </div>
              </div>

              <div className="container overflow-hidden">
                <div className="row gy-4 gy-xl-0">
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div
                      className="card border bg-dark-0 border-bottom border-secondary shadow-sm"
                      style={{ background: "#0e0b1e" }}
                    >
                      <div className="card-body text-center p-3 p-xxl-5">
                        <img src={social} alt="" srcSet="" />
                        <h4 className="mb-3 mt-2 text-white">Social Engagement</h4>
                        <p className="mb-4 text-secondary">
                          Every service with the intention to build a secure and
                          robost system for organisation
                        </p>

                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div
                      className="card border bg-dark-0 border-bottom border-secondary shadow-sm"
                      style={{ background: "#0e0b1e" }}
                    >
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={secure} alt="" />
                        <h4 className="my-4 text-white">Secure Bussines </h4>
                        <p className="mb-4 text-secondary">
                          The best security we you want can be provided only by
                          us to have the best sources of sheilds
                        </p>

                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div
                      className="card border  border-bottom border-secondary shadow-sm"
                      style={{ background: "#0e0b1e" }}
                    >
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={hearts} alt="" srcSet="" />
                        <h4 className="my-4 text-white">Peace of Heart</h4>
                        <p className="mb-4 text-secondary">
                          Get your heart the peace of having the best cyber
                          security you can have. Always onto your side.
                        </p>

                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div
                      className="card border bg-dark-0 border-bottom border-secondary shadow-sm"
                      style={{ background: "#0e0b1e" }}
                    >
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={support} alt="" srcSet="" />
                        <h4 className="my-4 text-white">24/7 Support</h4>
                        <p className="mb-4 text-secondary">
                          The best support you can have is here. We are 24/7
                          just away from one call for you .
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="container   ">
          <h2 className="display-5 mb-5 text-center" >
            The Critical Procedures We Operate <br /> For Cyber Security
          </h2>

          <div className="row mt-5 align-items-center">
            <div className="col-md-6 overflow-hidden  " data-aos="fade-up-right">
              <Lottie animationData={working} loop={true} autoplay={true} />
            </div>
            <div className="col-md-6 mt-3 " data-aos="fade-up-left">
              <div className="">
                <div style={{ color: "#00ffe8", fontSize: "1.1rem" }}>
                  About Us
                </div>
                <div className="fs-2 fw-bolder">
                  We provide professional cyber security for your business
                </div>
                <div style={{ fontSize: "1rem", color: "#a6a5a6" }}>
                  Cyber Security explain the rules for how employees,
                  consultants, partners, board members, and other end-users
                  access online applications and internet resources, send data
                  over networks, and otherwise practice responsible security
                </div>
                <div className="mt-3">
                  <div className="d-flex flex-column ">
                    <div className="fw-semibold">
                      <i className="fa-sharp fa-solid fa-check rounded-circle border border-white p-2 mb-2 "></i>{" "}
                      Being best in India to provide Cyber Security
                    </div>
                    <div className="fw-semibold">
                      <i className="fa-sharp fa-solid fa-check rounded-circle  border border-white p-2 mb-2 "></i>
                      {"  "}
                      Perimum    beyond your expectations
                    </div>
                    <div className="fw-semibold">
                      <i className="fa-sharp fa-solid fa-check rounded-circle  border border-white p-2  "></i>{" "}
                      Take a look at our best project
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="row align-items-center">
            {/* Text content */}
            <div className="col-md-6">
              <div data-aos="fade-down-right">
                <div style={{ color: "#00ffe8", fontSize: "1.1rem" }}>
                  Why Choose Us
                </div>
                <div className="fs-2 fw-bold">
                  We Protect you from Cyber attacks
                </div>
                <div style={{ fontSize: "1rem", color: "#a6a5a6" }}>
                  Our thought process in any business opportunity is that of a
                  consultant, rather than that of a salesperson. Our intent at
                  Saga, at all times, is to be thorough in our approach,
                  whatever be the nature and size of the opportunity at hand.
                </div>
                <div className="mt-3">
                  <div className="d-flex flex-column ">
                    <div className="fw-semibold">
                      <i className="fa-sharp fa-solid fa-check rounded-circle border border-white p-2 mb-2 "></i>{" "}
                      Being best in India to provide Cyber Security
                    </div>
                    <div className="fw-semibold">
                      <i className="fa-sharp fa-solid fa-check rounded-circle border border-white p-2 mb-2 "></i>{" "}
                      Premium Services beyond your expectations
                    </div>
                    <div className="fw-semibold">
                      <i className="fa-sharp fa-solid fa-check rounded-circle border border-white p-2 "></i>{" "}
                      Take a look at our best projects
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Lottie animation */}
            <div className="col-md-6" data-aos="fade-down-left">
              <div>
                <Lottie animationData={analysis} loop={true} autoplay={true} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className=" mt-2 d-flex align-items-center justify-content-center "
        style={{ height: "50vh" }}
      >
        <img
          src={digital}
          alt=""
          className="img-fluid"
          style={{
            minWidth: minWidth,
            height: imageHeight,
            position: "relative",
            overflow: "hidden",
          }}
        />
        <div className=" text-center text-white position-absolute z-1 overflow-auto">
          <ScrollTrigger onExit={() => { setCounter(false) }} onEnter={() => { setCounter(true) }}>
            <div className="container">
              <div className="row" style={{ fontSize: "2rem" }}>
                <div className="col-md-3">
                  <div className="mb-2">
                    <h1 className="fw-bold">
                      {counter && <CountUp start={0} end={10} duration={3} delay={0} />}+</h1>
                    <p>Years Experience</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-2">
                    <h1 className="fw-bold">
                      {counter && <CountUp start={0} end={130457} duration={3} delay={0} />}+</h1>
                    <p>Device Protected</p>
                  </div>

                </div>
                <div className="col-md-3">
                  <div className="mb-2">
                    <h1 className="fw-bold">
                      {counter && <CountUp start={0} end={950} duration={3} delay={0} />}+</h1>
                    <p>Confident Clients</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-2">
                    <h1 className="fw-bold">
                      {counter && <CountUp start={0} end={50} duration={3} delay={0} />}+</h1>
                    <p>Awards Received</p>
                  </div>
                </div>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </div>
    </div>
  );
};

export default Home;
