import React,{useEffect} from "react";
import server from "../Assets/server.svg";
import security from "../Assets/security.svg";
import nocsoc from "../Assets/NOC_SOC.svg";
import compliance from "../Assets/compliance.svg";
import remote from "../Assets/remote.svg";
import protect from '../Assets/protect.svg'
import network from '../Assets/networking.svg'
import laptop from '../Assets/laptop.svg'
import folder from '../Assets/folder.svg'
import cloud from '../Assets/cloud.svg'
import license from '../Assets/license.svg'
import surveillance from '../Assets/surveillance.svg'
import home from '../Assets/home.svg'
import videoCall from '../Assets/videoCall.svg'
import develop_app from '../Assets/develop_app.svg'
import web from '../Assets/web.svg'
import AOS from 'aos'
import "aos/dist/aos.css"
const Solutions = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 })


  }, [])
  return (
    <div>
      <section className="py-5 py-xl-8">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center">Solutions</h2>
              <p className="text-secondary mb-5 text-center">
              Our solutions provide a range of services to help you achieve your business goals. Having the right service delivery team is crucial for your business success. 
              </p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container overflow-hidden">
          <div className="row gy-5">
            <div className="col-12 col-sm-6 col-lg-4"  data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={security}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />

                <h5 className="m-2">CYBER SECURITY SOLUTIONS</h5>
                <p className="m-0 text-secondary">
                  Our tailored solutions offer top-notch protection with
                  proactive threat detection, robust encryption, and industry
                  best practices.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4"  data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={protect}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">DATA PROTECTION & RECOVERY</h5>
                <p className="m-0 text-secondary">
                  Ensure data security and swift recovery with our comprehensive
                  solutions, safeguarding your critical information against loss
                  or breach.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4"  data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={laptop}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />

                <h5 className="m-2">IT Infrastructure Solutions</h5>
                <p className="m-0 text-secondary">
                  Elevate your infrastructure with our tailored solutions,
                  optimizing performance and reliability to meet the demands of
                  your business.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4"  data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={network} alt="" srcSet="" style={{ height: "18vh" }} />

                <h5 className="m-2">NETWORKING SOLUTIONS</h5>
                <p className="m-0 text-secondary">
                  Our solutions optimize your network for seamless connectivity,
                  ensuring reliability and performance for your business
                  operations.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4"  data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={server} alt="" srcSet="" style={{ height: "18vh" }} />

                <h5 className="m-2">Servers</h5>
                <p className="m-0 text-secondary">
                  Experience reliable performance and seamless scalability with
                  our server solutions tailored to meet your business needs.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={folder}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">Storage Solutions</h5>
                <p className="m-0 text-secondary">
                  Optimize your data management with our scalable storage
                  solutions, ensuring secure and efficient access to your
                  valuable information.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={nocsoc}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">ENDPOINTS</h5>
                <p className="m-0 text-secondary">
                  Secure and streamline your device management with our
                  comprehensive endpoint solutions, ensuring optimal performance
                  and data protection.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={cloud} alt="" srcSet="" style={{ height: "18vh" }} />
                <h5 className="m-2">CLOUD COMPUTING</h5>
                <p className="m-0 text-secondary">
                  Empower your business with our versatile cloud computing
                  solutions, offering scalable infrastructure and seamless
                  integration for enhanced efficiency.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={compliance}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">MOBILITY SOLUTIONS</h5>
                <p className="m-0 text-secondary">
                  Enhance productivity and flexibility with our tailored
                  mobility solutions, providing seamless access to data and
                  applications on-the-go.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img
                  src={license}
                  alt=""
                  srcSet=""
                  style={{ height: "18vh" }}
                />
                <h5 className="m-2">SOFTWARE LICENSING</h5>
                <p className="m-0 text-secondary">
                  Simplify your software management with our flexible licensing
                  solutions, ensuring compliance and cost-effectiveness for your
                  business.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={remote} alt="" srcSet="" style={{ height: "18vh" }} />
                <h5 className="m-2">VIRTUALISATION</h5>
                <p className="m-0 text-secondary">
                  Maximize resource utilization and streamline IT operations
                  with our virtualization solutions, optimizing efficiency and
                  scalability for your infrastructure.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={surveillance} alt="" srcSet="" style={{ height: "18vh" }} />
                <h5 className="m-2">SURVEILLANCE SYSTEMS</h5>
                <p className="m-0 text-secondary">
                  Enhance security and monitoring capabilities with our advanced
                  surveillance solutions, providing comprehensive coverage and
                  real-time insights for your premises.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={home} alt="" srcSet="" style={{ height: "18vh" }} />
                <h5 className="m-2">AUTOMATION / AV SOLUTIONS</h5>
                <p className="m-0 text-secondary">
                  Streamline operations and elevate collaboration with our
                  integrated automation and AV solutions, ensuring optimal
                  efficiency and seamless communication.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={videoCall} alt="" srcSet="" style={{ height: "18vh" }} />
                <h5 className="m-2">AUDIO/VIDEO CONFERENCING</h5>
                <p className="m-0 text-secondary">
                  Facilitate seamless communication and collaboration with our
                  comprehensive audio/video conferencing solutions, enabling
                  productive remote meetings and discussions.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={develop_app} alt="" srcSet="" style={{ height: "18vh" }} />
                <h5 className="m-2">SOFTWARE / MOBILE APPS</h5>
                <p className="m-0 text-secondary">
                  Tailored solutions for web and mobile, optimizing operations
                  and enhancing business efficiency.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 " data-aos="fade-up">
              <div className="text-center px-xl-2">
                <img src={web} alt="" srcSet="" style={{ height: "18vh" }} />
                <h5 className="m-2">WEB DESIGN/DEVELOPMENT</h5>
                <p className="m-0 text-secondary">
                  Elevate your online presence with our expert web design and
                  development solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Solutions;
