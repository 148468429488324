import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authContext from '../Context/authContext';
import secure from "../Assets/laptop.svg";

const Login = ({ showAlert }) => {
  const { authLogin } = useContext(authContext);
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await authLogin(credentials);
      if (response?.data?.message === "Invalid credentials") {
        showAlert('Invalid Credentials', 'danger');
      } else {
        showAlert('Login Successful', 'success');
        navigate('/chats');
      }
    } catch (error) {
      console.error('Error during login:', error);
      const errorMessage = error?.response?.data?.message === "Invalid credentials"
        ? 'Invalid Credentials'
        : 'An error occurred during login';
      showAlert(errorMessage, 'danger');
    }
  };

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div className="container">
      <section className="py-3 py-md-5 text-white">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h3 className="fs-6 mb-2 text-uppercase text-center" style={{ color: "#00ffe8" }}>
                Get in Touch
              </h3>
              <h2 className="display-5 mb-4 mb-md-5 text-center">
                Login To Raise Your Ticket
              </h2>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-xl-center">
            <div className="col-12 col-lg-6">
              <img src={secure} alt="Secure" className="img-fluid p-5" />
            </div>
            <div className="col-12 col-lg-6">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <div className="border rounded shadow-sm overflow-hidden">
                    <form onSubmit={handleSubmit}>
                      <div className="row gy-4 gy-xl-5 p-4 p-xl-5">
                        <div className="col-12">
                          <label htmlFor="email" className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            style={{ background: "#0e0b1e" }}
                            type="email"
                            className="form-control text-white"
                            id="email"
                            name="email"
                            value={credentials.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="password" className="form-label">
                            Password <span className="text-danger">*</span>
                          </label>
                          <input
                            style={{ background: "#0e0b1e" }}
                            type="password"
                            className="form-control text-white"
                            id="password"
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-11 m-auto p-3">
                        <div className="d-grid">
                          <button
                            className="btn btn-primary btn-lg"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="text-center p-3 text-decoration-none">
                      Do not have an Account? <Link className="text-decoration-none" to="/register">Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
