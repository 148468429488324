import DataContext from './dataContext'
import axios from 'axios';
import React, { useState } from 'react';

const AuthState = (props) => {
    const [companies, setCompanies] = useState([]);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get('https://saga-backend.vercel.app/api/companies', {
                headers: {
                    Authorization: localStorage.getItem('data') // Pass token in Authorization header
                }
            });
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const companyDelete = async (companyId) => {
        try {
            const response = await axios.delete(`https://saga-backend.vercel.app/api/companies/${companyId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('data'),
                },
            });

            if (response.status !== 200) {
                console.error("Error deleting company:", response.data);
            }

            // After successfully deleting, update the company list
            fetchCompanies()
        } catch (error) {
            console.error("Error deleting company:", error);
        }
    };
    // 
    // 
    // Contacts 

    const [contacts, setContacts] = useState([]);

    const getContacts = async () => {
        try {
            const response = await axios.get(`https://saga-backend.vercel.app/api/contacts`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('data'),
                },
            });

            if (response.data && response.data.length > 0) {
                // Initialize showFullDescription state for each contact
                const updatedContacts = response.data.map(contact => ({
                    ...contact,
                    showFullDescription: false
                }));
                setContacts(updatedContacts);
            } else {
                throw new Error('No data in the response');
            }
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    const contactDelete = async (contactId) => {
        try {
            const response = await axios.delete(`https://saga-backend.vercel.app/api/contacts/${contactId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('data'),
                },
            });

            if (response.status !== 200) {
                console.error("Error deleting contact:", response.data);
            }

            setContacts(prevContacts => prevContacts.filter(contact => contact._id !== contactId));
        } catch (error) {
            console.error("Error deleting contact:", error);
        }
    };



    return (
        <DataContext.Provider value={{ companies, fetchCompanies, companyDelete, contacts, getContacts, contactDelete }}>
            {props.children}
        </DataContext.Provider>
    )
}
export default AuthState;
