import React, { useEffect, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DataContext from '../Context/dataContext';
import Auth from '../Context/authContext';

const ContactCard = ({ showAlert }) => {
    const { contacts, getContacts, contactDelete } = useContext(DataContext);
    const navigate = useNavigate();

    const { access } = useContext(Auth);
    const [isAdminAccess, setIsAdminAccess] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await access();
                setIsAdminAccess(response.adminAccess);
                if (!isAdminAccess) {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching access:', error);
            }
        };

        fetchData();
    }, [access, isAdminAccess, navigate]);

    useEffect(() => {
        if (!localStorage.getItem('data')) {
            navigate('/');
        }
        getContacts();
    }, [navigate, getContacts]);

    return (
        <div className='container'>
            <Link to='/quotaResponse' className="btn btn-outline-light">Quota Response</Link>
            <Link className="disabled btn btn-light mx-2">Contact Response</Link>
            <Link to={'/chats'} className="btn btn-outline-light mx-2">Chats</Link>

            <button onClick={() => { localStorage.removeItem('data'); navigate('/'); showAlert('Logout Successfully', 'success'); }} className="btn btn-outline-light">Log Out</button>

            <div className='row my-3 '>
                {contacts.map((contact) => (
                    <div key={contact._id} className='col-md-6 my-2 ' >
                        <div className='card bg-dark text-light ' >
                            <div className='card-body'>
                                <h5 className='card-title'>{contact.full_name}</h5>
                                <div className='card-text my-1'><strong>Email:</strong> {contact.email}</div>
                                <div className='card-text my-1'><strong>Phone:</strong> {contact.phone_no}</div>
                                <div className='card-text my-1'><strong>Subject:</strong> {contact.subject}</div>
                                <div className='card-text my-1'><strong>Description:</strong> {contact.description}</div>
                                <button className='btn mx-2 btn-outline-light' onClick={() => contactDelete(contact._id)} >
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContactCard;
