import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';

const Quota = ({showAlert}) => {
    const history = useNavigate();

    // State variables for storing input field values
    const [companyName, setCompanyName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [contactPersonMail, setContactPersonMail] = useState('');
    const [numberOfLocations, setNumberOfLocations] = useState('');
    const [exchanges, setExchanges] = useState('');
    const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState('');
    const [comments, setComments] = useState('');
    const [firewall, setFirewall] = useState('NO');
    const [activeDirectory, setActiveDirectory] = useState('NO');
    const [inHouseBuiltApplication, setInHouseBuiltApplication] = useState('NO');
    const [algoTrading, setAlgoTrading] = useState('NO');
    const [clearingNumber, setClearingNumber] = useState('');
    const [contactPhoneNumber, setContactPhoneNumber] = useState('');
    const [numberOfITAssets, setNumberOfITAssets] = useState('');

    const resetForm = () => {
        setCompanyName('');
        setContactPerson('');
        setCompanyAddress('');
        setContactPersonMail('');
        setNumberOfLocations('');
        setExchanges('');
        setHowDidYouHearAboutUs('');
        setComments('');
        setFirewall('NO');
        setActiveDirectory('NO');
        setInHouseBuiltApplication('NO');
        setAlgoTrading('NO');
        setClearingNumber('');
        setContactPhoneNumber('');
        setNumberOfITAssets('');
    };
    // Function to get current date in DD/MM/YYYY format according to IST
    const getISTCurrentDate = () => {
        const istDate = moment().tz('Asia/Kolkata').format('DD/MM/YYYY');
        return istDate;
    };
     // eslint-disable-next-line
    const [createdAt, setCreatedAt] = useState(getISTCurrentDate());

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(
                'https://saga-backend.vercel.app/api/companies',
                {
                    company_name: companyName,
                    company_address: companyAddress,
                    contact_person_technical: contactPerson,
                    contact_person_email: contactPersonMail,
                    number_of_locations: numberOfLocations,
                    contact_phone_number: contactPhoneNumber,
                    number_of_it_assets: numberOfITAssets,
                    exchanges: exchanges,
                    firewall: firewall,
                    active_directory: activeDirectory,
                    in_house_built_application: inHouseBuiltApplication,
                    algo_trading: algoTrading,
                    how_did_you_hear_about_us: howDidYouHearAboutUs,
                    comments: comments,
                    clearing_number: clearingNumber
                }
            );

            console.log('Response:', response.data);
            // Redirect or show success message here if needed
            showAlert('Form Submited Successful', 'success')
            resetForm()
        } catch (error) {
            console.error("Error adding company:", error);
            // Show error message if needed
            showAlert('Error in form submission ', 'danger')
        }
    };

    return (
        <div>
            <div>
                <i className="fa-solid fa-left-long btn  btn-lg rounded-pill mx-4 my-2" id='up' onClick={() => { history(-1); }}></i>
            </div>
            <div className="container">
                <div className="row justify-content-center align-items-center ">
                    <div className="col-12 col-lg-9 col-xl-7">
                        <div className="card shadow-2-strong card-registration">
                            <div className="card-body p-md-4 text-light" style={{ background: '#0e0b1e' }}>
                                <h3 className="mb-3 pb-2 pb-md-0 mb-md-5">Get Free Quota</h3>
                                <form onSubmit={handleFormSubmit}>
                                    {/* Existing form fields */}
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-outline">
                                                <input required type="text" id="companyName" className="form-control bg-dark text-light" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                                <label className="form-label" htmlFor="companyName">Company Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-outline">
                                                <input required type="text" id="contactPerson" className="form-control bg-dark text-light" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} />
                                                <label className="form-label" htmlFor="contactPerson">Contact Person</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3 d-flex align-items-center">
                                            <div className="form-outline datepicker w-100">
                                                <input required type="text" className="form-control bg-dark text-light" id="companyAddress" value={companyAddress} onChange={(e) => setCompanyAddress(e.target.value)} />
                                                <label htmlFor="companyAddress" className="form-label">Company Address</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 d-flex align-items-center">
                                            <div className="form-outline datepicker w-100">
                                                <input required type="text" className="form-control bg-dark text-light" id="contactPersonMail" value={contactPersonMail} onChange={(e) => setContactPersonMail(e.target.value)} />
                                                <label htmlFor="contactPersonMail" className="form-label">Contact Person Mail</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3 d-flex align-items-center">
                                            <div className="form-outline datepicker w-100">
                                                <input required type="number" className="form-control bg-dark text-light" id="numberOfLocations" value={numberOfLocations} onChange={(e) => setNumberOfLocations(e.target.value)} />
                                                <label htmlFor="numberOfLocations" className="form-label">Number of Locations</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-outline">
                                                <input required type="text" id="exchanges" className="form-control bg-dark text-light" value={exchanges} onChange={(e) => setExchanges(e.target.value)} />
                                                <label className="form-label" htmlFor="exchanges">Exchanges</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3 pb-2">
                                            <div className="form-outline">
                                                <input required type="text" id="howDidYouHearAboutUs" className="form-control bg-dark text-light" value={howDidYouHearAboutUs} onChange={(e) => setHowDidYouHearAboutUs(e.target.value)} />
                                                <label className
="form-label" htmlFor="howDidYouHearAboutUs">How did you hear about us?</label>
</div>
</div>
<div className="col-md-6 mb-3 pb-2">
<div className="form-outline">
    <input required type="text" id="comments" className="form-control bg-dark text-light" value={comments} onChange={(e) => setComments(e.target.value)} />
    <label className="form-label" htmlFor="comments">Comments</label>
</div>
</div>
</div>
{/* Additional questions */}
<div className="row">
<div className="col-md-6 mb-3 pb-2">
<label className="form-label">Do you have firewall?</label>
<div>
    <input type="radio" className="form-check-input" id="firewallYes" name="firewall" value="YES" checked={firewall === 'YES'} onChange={(e) => setFirewall(e.target.value)} />
    <label htmlFor="firewallYes">YES</label>
    <input type="radio" className="form-check-input" id="firewallNo" name="firewall" value="NO" checked={firewall === 'NO'} onChange={(e) => setFirewall(e.target.value)} />
    <label htmlFor="firewallNo">NO</label>
</div>
</div>
<div className="col-md-6 mb-3 pb-2">
<label className="form-label">Do you have Active Directory?</label>
<div>
    <input type="radio" className="form-check-input" id="activeDirectoryYes" name="activeDirectory" value="YES" checked={activeDirectory === 'YES'} onChange={(e) => setActiveDirectory(e.target.value)} />
    <label htmlFor="activeDirectoryYes"> YES</label>
    <input type="radio" className="form-check-input" id="activeDirectoryNo" name="activeDirectory" value="NO" checked={activeDirectory === 'NO'} onChange={(e) => setActiveDirectory(e.target.value)} />
    <label htmlFor="activeDirectoryNo"> NO</label>
</div>
</div>
</div>
<div className="row">
<div className="col-md-6 mb-3 pb-2">
<label className="form-label">Do you have In-house Built Application?</label>
<div>
    <input type="radio" className="form-check-input" id="inHouseBuiltApplicationYes" name="inHouseBuiltApplication" value="YES" checked={inHouseBuiltApplication === 'YES'} onChange={(e) => setInHouseBuiltApplication(e.target.value)} />
    <label htmlFor="inHouseBuiltApplicationYes">YES</label>
    <input type="radio" className="form-check-input" id="inHouseBuiltApplicationNo" name="inHouseBuiltApplication" value="NO" checked={inHouseBuiltApplication === 'NO'} onChange={(e) => setInHouseBuiltApplication(e.target.value)} />
    <label htmlFor="inHouseBuiltApplicationNo">NO</label>
</div>
</div>
<div className="col-md-6 mb-3 pb-2">
<label className="form-label">Algo Trading</label>
<div>
    <input type="radio" className="form-check-input" id="algoTradingYes" name="algoTrading" value="YES" checked={algoTrading === 'YES'} onChange={(e) => setAlgoTrading(e.target.value)} />
    <label htmlFor="algoTradingYes">YES</label>
    <input type="radio" className="form-check-input" id="algoTradingNo" name="algoTrading" value="NO" checked={algoTrading === 'NO'} onChange={(e) => setAlgoTrading(e.target.value)} />
    <label htmlFor="algoTradingNo">NO</label>
</div>
</div>
</div>
<div className="row">
<div className="col-md-6 mb-3 pb-2">
<div className="form-outline">
    <input required type="text" id="numberOfITAssets" className="form-control bg-dark text-light" value={numberOfITAssets} onChange={(e) => setNumberOfITAssets(e.target.value)} />
    <label className="form-label" htmlFor="numberOfITAssets">Number of IT Assets</label>
</div>
</div>
<div className="col-md-6 mb-3 pb-2">
<div className="form-outline">
    <input required type="text" id="contactPhoneNumber" className="form-control bg-dark text-light" value={contactPhoneNumber} onChange={(e) => setContactPhoneNumber(e.target.value)} />
    <label className="form-label" htmlFor="contactPhoneNumber">Contact Phone Number</label>
</div>
</div>
</div>
<div className="row">
<div className="col-md-6 mb-3 pb-2">
<div className="form-outline">
    <input required type="text" id="clearingNumber" className="form-control bg-dark text-light" value={clearingNumber} onChange={(e) => setClearingNumber(e.target.value)} />
    <label className="form-label" htmlFor="clearingNumber">Clearing Number</label>
</div>
</div>
</div>
<div className="mt-4 pt-2">
<input required className="btn btn-primary btn-lg" type="submit" value="Submit" />
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
);
};

export default Quota;
